/* eslint-disable camelcase */
import { PURGE } from 'redux-persist';
import { copyState, validatePersistedState, enrich } from 'state/helpers';
import { onFetchFailed, onFetchStarted, onListFetchSuccess } from 'state/defaultLogic';
import * as types from './types';
import * as constants from '../../constants/api';

export const initialState = {
  VERSION: 1.04,
  teamGameplans: {},
  actionlog: {},
};

// transform widgets - parse the rich text to an object
const widgetRichTextTransformFunc = widget => {
  if (widget.elements && widget.elements.length > 0) {
    for (const element of widget.elements) {
      if (element.content) {
        element.content = JSON.parse(element.content);
      }
    }
  }
  return widget;
};

// transform the received object to have a shape more suited
// for the access patterns
const gameplanDataTransformFunc = (state, payload) => {
  // convert domain ID to legacy format:
  const domain_id = payload.domain_id.startsWith('TEAM_')
    ? payload.domain_id.replace('TEAM_', '')
    : payload.domain_id;

  for (const gp of state.teamGameplans[domain_id].data) {
    if (!gp.processed) {
      const widgetData = {};
      const widgetVisibility = {};
      for (const widget of gp.widget_data) {
        widgetData[widget.id] = widgetRichTextTransformFunc(widget);
        if (widget.widget_type !== 'CUSTOM_CARD') {
          widgetVisibility[widget.widget_type] = true;
        }
      }
      gp.widget_data = widgetData;
      gp.widget_visibility = widgetVisibility;
      gp.processed = true;
    }
  }
};

function createdGameplan(state, action) {
  const newState = copyState(state);
  const { gameplan, requestID } = action.payload;

  // convert domain ID to legacy format:
  const domain_id = action.payload.domain_id.startsWith('TEAM_')
    ? action.payload.domain_id.replace('TEAM_', '')
    : action.payload.domain_id;

  if (!(domain_id in newState.teamGameplans)) {
    newState.teamGameplans[domain_id] = enrich({
      fetchStatus: constants.OK,
      data: [],
    });
  } else {
    newState.teamGameplans[domain_id].data = newState.teamGameplans[domain_id].data.filter(
      gp => gp.id !== gameplan.id,
    );
  }

  newState.teamGameplans[domain_id].data.push(gameplan);

  gameplanDataTransformFunc(state, action.payload);
  // update actionlog
  newState.actionlog[requestID] = { result: 'ok', gameplan_id: gameplan.id };
  // touch the state object to force a re-render
  newState.teamGameplans[domain_id] = { ...newState.teamGameplans[domain_id] };
  return newState;
}

function toggleWidgets(state, action) {
  const newState = copyState(state);
  const { id, order, widget_data, requestID } = action.payload;

  // convert domain ID to legacy format:
  const domain_id = action.payload.domain_id.startsWith('TEAM_')
    ? action.payload.domain_id.replace('TEAM_', '')
    : action.payload.domain_id;

  for (const gamePlan of newState.teamGameplans[domain_id].data) {
    if (gamePlan.id === id) {
      gamePlan.order = order;
      gamePlan.widget_data = widget_data;
    }
  }

  gameplanDataTransformFunc(state, action.payload);
  // update actionlog
  newState.actionlog[requestID] = { result: 'ok' };
  // touch the state object to force a re-render
  newState.teamGameplans[domain_id] = { ...newState.teamGameplans[domain_id] };
  return newState;
}

function editGamePlanWidgetOrder(state, action) {
  const newState = copyState(state);
  const { domain_id, gameplan_id, widget_id, position, requestID } = action.payload;
  // Optimistic rendering, we'll move the card when the call is sent

  let prevColumn = null;
  let prevIndex = null;

  for (const gamePlan of newState.teamGameplans[domain_id].data) {
    if (gamePlan.id === gameplan_id) {
      // eslint-disable-next-line no-loop-func
      gamePlan.order.forEach((statecolumn, columnIndex) => {
        for (const i of statecolumn.keys()) {
          const widget = statecolumn[i];
          if (widget === widget_id) {
            prevColumn = columnIndex;
            prevIndex = i;
            break;
          }
        }
      });
      if (prevColumn !== null && prevIndex !== null) {
        gamePlan.order[prevColumn].splice(prevIndex, 1);
        gamePlan.order[position.column].splice(position.index, 0, widget_id);
      }
    }
  }
  // update actionlog
  newState.actionlog[requestID] = { result: 'ok' };
  // touch the state object to force a re-render
  newState.teamGameplans[domain_id] = { ...newState.teamGameplans[domain_id] };
  return newState;
}

function editGamePlanWidget(state, action) {
  const newState = copyState(state);
  const { requestID, widget_data, domain_id, gameplan_id } = action.payload;

  const widgetId = widget_data[0].id;
  for (const gamePlan of newState.teamGameplans[domain_id].data) {
    if (gamePlan.id === gameplan_id) {
      gamePlan.widget_data[widgetId] = widgetRichTextTransformFunc(widget_data[0]);
    }
  }
  // update actionlog
  newState.actionlog[requestID] = { result: 'ok' };
  // touch the state object to force a re-render
  newState.teamGameplans[domain_id] = { ...newState.teamGameplans[domain_id] };
  return newState;
}

function addApiErrorToState(state, action) {
  const newState = copyState(state);
  newState.actionlog[action.payload.requestID] = { result: 'error' };
  if (!!action.payload.error && !!action.payload.error.error) {
    newState.actionlog[action.payload.requestID].message = action.payload.error.error;
  }
  return newState;
}
// eslint-disable-next-line default-param-last
export default (state = JSON.parse(JSON.stringify(initialState)), action) => {
  state = validatePersistedState(state, initialState);
  switch (action.type) {
    case types.CREATED_GAMEPLAN:
    case types.CREATED_BLANK_CARD:
    case types.DELETED_GAMEPLAN_WIDGET:
      return createdGameplan(state, action);
    case types.FETCH_GAMEPLANS:
      return onFetchStarted({
        state,
        payload: action.payload,
        key: 'teamGameplans',
        payloadIdKey: 'domain_id',
      });
    case types.RECEIVED_GAMEPLANS:
      return onListFetchSuccess({
        state,
        payload: action.payload,
        key: 'teamGameplans',
        payloadDataKey: 'gameplan_data',
        payloadIdKey: 'domain_id',
        postProcessTransform: gameplanDataTransformFunc,
      });
    case types.FAILED_GAMEPLANS:
      return onFetchFailed({
        state,
        payload: action.payload,
        key: 'teamGameplans',
        payloadIdKey: 'domain_id',
      });
    case types.MOVE_GAMEPLAN_WIDGET:
      // optimistic rendering
      // TODO: revert back to previous order on error and inform user
      // in case of errors
      return editGamePlanWidgetOrder(state, action);

    case types.EDITED_GAMEPLAN_WIDGET:
      return editGamePlanWidget(state, action);

    case types.EDITED_GAMEPLAN_WIDGETS_VISIBILITY:
      return toggleWidgets(state, action);
    case types.ERROR_RECEIVED_FROM_API:
      return addApiErrorToState(state, action);
    case 'LOGOUT':
    case PURGE:
      return JSON.parse(JSON.stringify(initialState));
    default:
      return state;
  }
};
