import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Popover, Typography, Divider, Box, IconButton, Button } from '@mui/material';
import BackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import Form from 'Components/Library/Forms/Form';
import FormButton from 'Components/Library/Forms/Elements/FormButton';
import FormCheckbox from 'Components/Library/Forms/Elements/FormCheckbox';

import { gameplansActions } from 'state/ducks/gameplans';
import { openAddGameplanCardDialog } from 'config/ModalProvider/helpers';
import { isEnabled } from 'config/flags';
import { appstatusSelectors } from 'state/ducks/appstatus';
import InterlockIcon from './Icons/InterlockIcon';
import CommitmentIcon from './Icons/CommitmentIcon';
import MenuOption from './MenuOption';
import ContentCardIcon from './Icons/ContentCardIcon';
import GamePlanIcon from './Icons/GamePlanIcon';
import VisionAndPurposeIcon from './Icons/VisionAndPurposeIcon';
import ObjectivesIcon from './Icons/ObjectivesIcon';
import ValuesIcon from './Icons/ValuesIcon';
import WillNotDoIcon from './Icons/WillNotDoIcon';
import MethodsIcon from './Icons/MethodsIcon';
import FeatureFlag from 'Components/Library/FeatureFlag';
import AiGameplanSuggestionsFlow from './AiGameplanSuggestionsFlow';
import CopyGamePlanDialog from './CopyGamePlanDialog';
import { findPeriodWithPreviousGameplan } from '../helpers';
import useGameplanData from 'hooks/useGameplanData';
import { objectivesSelectors } from 'state/ducks/objectives';

const headingStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: 24,
};

const defaultVisibility = {
  VISION_AND_PURPOSE: false,
  VALUES_AND_DRIVERS: false,
  WILL_NOT_DO: false,
  OKRS: false,
  COMMITMENTS: false,
  INTERLOCKS: false,
  METHODS: false,
};

const schema = {
  type: 'object',
  properties: {
    gameplanId: { type: 'string' },
    widget_visibility: {
      type: 'object',
      properties: {
        VISION_AND_PURPOSE: { type: 'boolean' },
        VALUES_AND_DRIVERS: { type: 'boolean' },
        WILL_NOT_DO: { type: 'boolean' },
        OKRS: { type: 'boolean' },
        COMMITMENTS: { type: 'boolean' },
        INTERLOCKS: { type: 'boolean' },
        METHODS: { type: 'boolean' },
      },
    },
  },
};

function AddGamePlanMenu({
  anchorEl,
  onClose,
  teamId,
  gameplanId,
  visibility,
  ltperiod,
  stperiod,
  usePeriodicGameplans,
}) {
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [copyDialogOpen, setCopyDialogOpen] = useState(false);

  const isNew = !Boolean(gameplanId);
  const gameplans = useGameplanData(teamId);
  const appstatus = useSelector(state => appstatusSelectors.selectStatus(state.main.appstatus));
  const periodConfig = useSelector(state =>
    objectivesSelectors.selectPeriodConfig(state.main.objectives),
  );

  // A gameplan needs to exist before a custom card can be added, if there's no gameplan
  // let's create one and open the create card dialog from the onSubmitSuccess callback

  // Note that the Form needs to be mounted in any scenario, otherwise the form would
  // potentially get unmounted after a gameplan is created and the callback would never
  // be called
  const addCardNode = isNew ? (
    <FormButton
      render={buttonProps => (
        <MenuOption
          title={t('gameplans.addMenu.contentCardOptionTitle')}
          description={t('gameplans.addMenu.contentCardOptionDescription')}
          Icon={ContentCardIcon}
          {...buttonProps}
        />
      )}
    />
  ) : (
    <MenuOption
      title={t('gameplans.addMenu.contentCardOptionTitle')}
      description={t('gameplans.addMenu.contentCardOptionDescription')}
      Icon={ContentCardIcon}
      name="gameplans-add-custom-menuoption"
      onClick={() => {
        openAddGameplanCardDialog(teamId, gameplanId, navigate, location);
      }}
    />
  );

  const periodCfg = {};
  if (usePeriodicGameplans) {
    periodCfg.ltperiod = ltperiod;
    periodCfg.stperiod = stperiod.split('-')[1];
  }

  // Storing the initialValues in a ref and computing it with useEffect
  // to prevent unneccessary re-renders
  const initialValues = useRef(null);
  useEffect(() => {
    if (isNew) {
      const vis = Object.keys(defaultVisibility).reduce((o, key) => ({ ...o, [key]: true }), {});
      if (!isEnabled('INTERLOCKS', appstatus)) {
        vis.INTERLOCKS = false;
      }
      initialValues.current = {
        ...periodCfg,
        widget_visibility: vis,
      };
    } else {
      initialValues.current = {
        widget_visibility: { ...defaultVisibility, ...visibility },
        gameplan_id: gameplanId,
      };
    }
    // we specifically do not want this to be re-executed unless isNew changes
    // eslint-disable-next-line
  }, [isNew]);

  const gameplansPerPeriod = {};

  if (usePeriodicGameplans) {
    for (const gameplan of gameplans.data) {
      if (gameplan.stperiod) {
        gameplansPerPeriod[`${gameplan.ltperiod}-${gameplan.stperiod}`] = gameplan;
      }
    }
  }

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', width: 264 }}>
        {step === 0 && (
          <>
            <Box sx={headingStyle}>
              <Typography variant="body2" color="text.disabled">
                {t('gameplans.addMenu.title')}
              </Typography>
            </Box>
            <MenuOption
              title={t('gameplans.addMenu.gamePlanOptionTitle')}
              description={t('gameplans.addMenu.gamePlanOptionDescription')}
              Icon={GamePlanIcon}
              onClick={() => setStep(1)}
              name="gameplans-add-gameplan"
            />
            <Divider />
            <Form
              name="edit-game-plan-custom-card"
              schema={schema}
              initialValues={{ ...periodCfg, widget_visibility: { ...defaultVisibility } }}
              submitActionCreator={gameplansActions.createGameplan}
              additionalProperties={{ domain_id: teamId }}
              stateSlice="main.gameplans"
              onSubmitSuccess={result => {
                onClose();
                openAddGameplanCardDialog(teamId, result.gameplan_id, navigate, location);
              }}
            >
              {addCardNode}
            </Form>
            <FeatureFlag flag="OTHER-AI">
              <AiGameplanSuggestionsFlow
                teamId={teamId}
                isNew={isNew}
                stperiod={stperiod}
                ltperiod={ltperiod}
                gameplanId={gameplanId}
                onSuccess={() => {
                  onClose();
                }}
              />
            </FeatureFlag>
            {usePeriodicGameplans &&
              findPeriodWithPreviousGameplan(periodConfig, stperiod, gameplansPerPeriod) && (
                <MenuOption
                  title={t('general.copy')}
                  description={t('gameplans.addMenu.copyOptionDescription')}
                  Icon={GamePlanIcon}
                  onClick={() => setCopyDialogOpen(true)}
                  name="gameplans-add-gameplan"
                />
              )}
          </>
        )}
        {copyDialogOpen && (
          <CopyGamePlanDialog
            teamId={teamId}
            targetPeriod={stperiod}
            open
            onClose={() => setCopyDialogOpen(false)}
            onSuccess={() => {
              setCopyDialogOpen(false);
              onClose();
            }}
          />
        )}
        {step === 1 && (
          <Form
            name="edit-game-plan-widgets"
            schema={schema}
            initialValues={initialValues.current}
            submitActionCreator={
              isNew
                ? gameplansActions.createGameplan
                : gameplansActions.editGamePlanWidgetsVisibility
            }
            additionalProperties={{ domain_id: teamId }}
            stateSlice="main.gameplans"
            preventSaveIncomplete
            onSubmitSuccess={() => {
              onClose();
            }}
          >
            <Box sx={headingStyle}>
              <IconButton onClick={() => setStep(0)} size="small">
                <BackIcon />
              </IconButton>
              <Typography variant="body2" color="text.disabled">
                {t('gameplans.addMenu.selectGamePlanWidgetsTitle')}
              </Typography>
            </Box>
            <FormCheckbox
              fieldName="widget_visibility.VISION_AND_PURPOSE"
              render={fieldProps => (
                <MenuOption
                  title={t('gameplans.addMenu.visionAndPurposeOptionTitle')}
                  description={t('gameplans.addMenu.visionAndPurposeOptionDescription')}
                  Icon={VisionAndPurposeIcon}
                  mode="checkbox"
                  {...fieldProps}
                />
              )}
            />
            <FormCheckbox
              fieldName="widget_visibility.OKRS"
              render={fieldProps => (
                <MenuOption
                  title={t('gameplans.addMenu.okrOptionTitle')}
                  description={t('gameplans.addMenu.okrOptionDescription')}
                  Icon={ObjectivesIcon}
                  mode="checkbox"
                  {...fieldProps}
                />
              )}
            />
            <FormCheckbox
              fieldName="widget_visibility.INTERLOCKS"
              render={fieldProps => (
                <MenuOption
                  title={t('gameplans.addMenu.interlocksOptionTitle')}
                  description={t('gameplans.addMenu.interlocksOptionDescription')}
                  Icon={InterlockIcon}
                  mode="checkbox"
                  {...fieldProps}
                />
              )}
            />
            <FormCheckbox
              fieldName="widget_visibility.COMMITMENTS"
              render={fieldProps => (
                <MenuOption
                  title={t('gameplans.addMenu.commitmentsOptionTitle')}
                  description={t('gameplans.addMenu.commitmentsOptionDescription')}
                  Icon={CommitmentIcon}
                  mode="checkbox"
                  {...fieldProps}
                />
              )}
            />
            <FormCheckbox
              fieldName="widget_visibility.METHODS"
              render={fieldProps => (
                <MenuOption
                  title={t('gameplans.addMenu.methodsOptionTitle')}
                  description={t('gameplans.addMenu.methodsOptionDescription')}
                  Icon={MethodsIcon}
                  mode="checkbox"
                  {...fieldProps}
                />
              )}
            />
            <FormCheckbox
              fieldName="widget_visibility.VALUES_AND_DRIVERS"
              render={fieldProps => (
                <MenuOption
                  title={t('gameplans.addMenu.valuesOptionTitle')}
                  description={t('gameplans.addMenu.valuesOptionDescription')}
                  Icon={ValuesIcon}
                  mode="checkbox"
                  {...fieldProps}
                />
              )}
            />
            <FormCheckbox
              fieldName="widget_visibility.WILL_NOT_DO"
              render={fieldProps => (
                <MenuOption
                  title={t('gameplans.addMenu.willNotDoOptionTitle')}
                  description={t('gameplans.addMenu.willNotDoOptionDescription')}
                  Icon={WillNotDoIcon}
                  mode="checkbox"
                  {...fieldProps}
                />
              )}
            />
            <FormButton
              render={buttonProps => (
                <Button
                  variant="contained"
                  color="secondary"
                  {...buttonProps}
                  name="gameplans-add-gameplan-button"
                  sx={{ width: '100%' }}
                >
                  {isNew ? t('general.create') : t('general.update')}
                </Button>
              )}
            />
          </Form>
        )}
      </Box>
    </Popover>
  );
}

AddGamePlanMenu.propTypes = {
  anchorEl: PropTypes.object,
  onClose: PropTypes.func,
  teamId: PropTypes.string,
  gameplanId: PropTypes.string,
  ltperiod: PropTypes.string,
  stperiod: PropTypes.string,
  visibility: PropTypes.object,
  usePeriodicGameplans: PropTypes.bool,
};

export default AddGamePlanMenu;
