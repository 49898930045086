// This is not used now but will be used when we get the final
// pixel specs
export function findPeriodWithPreviousGameplan(periodConfig, selectedPeriod, gameplans) {
  if (!periodConfig || !periodConfig.ok || !selectedPeriod || !gameplans) {
    return null;
  }
  const sortedStPeriodIds = periodConfig.data.stperiods;
  for (let i = sortedStPeriodIds.indexOf(selectedPeriod); i >= 0; i--) {
    if (selectedPeriod !== sortedStPeriodIds[i] && gameplans[sortedStPeriodIds[i]]) {
      return sortedStPeriodIds[i];
    }
  }
}
