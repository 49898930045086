import { FormControlLabel } from '@mui/material';
import Container from 'Components/Library/BaseComponents/Container';
import { useSelector } from 'react-redux';
import { objectivesSelectors } from 'state/ducks/objectives';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';
import Button from 'Components/Library/BaseComponents/Button';
import Form from 'Components/Library/Forms';
import FormCheckbox from 'Components/Library/Forms/Elements/FormCheckbox';
import { gameplansActions } from 'state/ducks/gameplans';
import { WIDGET_TYPE_LOCALIZATION_KEYS } from '.';
import FormButton from 'Components/Library/Forms/Elements/FormButton';

interface CopyFormProps {
  targetPeriod: string;
  sourceGameplanId: string;
  options: Array<any>;
  onClose(): void;
  onSuccess(): void;
}

// a custom submitActionCreator to handle changing of the owner as it needs both DELETE and ADD -operations in one
export const createReduxAction = params => {
  const { ...rest } = params;
  const actionCreator = gameplansActions.copyGameplan;
  rest.widgets_to_copy = Object.keys(rest.widgets_to_copy).filter(
    key => rest.widgets_to_copy[key] === true,
  );
  return actionCreator(rest);
};

function CopyForm(props: CopyFormProps) {
  const { targetPeriod, sourceGameplanId, options, onClose, onSuccess } = props;
  const { t } = useTranslation();

  const ltperiod = useSelector((state: any) =>
    objectivesSelectors.selectLtPeriodForStPeriod(state.main.objectives, targetPeriod),
  );

  const initialValues = { widgets_to_copy: options.reduce((a, v) => ({ ...a, [v.id]: true }), {}) };

  const schema = {
    type: 'object',
    properties: {
      gameplanId: { type: 'string' },
      widgets_to_copy: {
        type: 'object',
        properties: options.reduce((a, v) => ({ ...a, [v.id]: { type: 'boolean' } }), {}),
      },
    },
  };

  return (
    <Form
      name="copy-gameplan-form"
      schema={schema}
      initialValues={initialValues}
      preventSaveIncomplete
      allowRefreshData
      submitActionCreator={createReduxAction}
      additionalProperties={{
        gameplan_id: sourceGameplanId,
        ltperiod: ltperiod,
        stperiod: targetPeriod.split('-')[1],
      }}
      stateSlice="main.gameplans"
      onSubmitSuccess={result => {
        onSuccess();
      }}
    >
      <Container spacing={1} m={0} alignItems="stretch">
        {options.map(widgetData => (
          <Container key={widgetData.id} direction="row" alignItems="center">
            <FormCheckbox
              fieldName={`widgets_to_copy.${widgetData.id}`}
              render={fieldProps => (
                <FormControlLabel
                  control={<Checkbox {...fieldProps} />}
                  label={
                    widgetData.widget_type === 'CUSTOM_CARD'
                      ? widgetData.name
                      : t(
                          `gameplans.addMenu.${
                            WIDGET_TYPE_LOCALIZATION_KEYS[widgetData.widget_type]
                          }`,
                        )
                  }
                />
              )}
            />
          </Container>
        ))}
        <Container sx={{ width: '100%' }} direction="row" justifyContent="flex-end">
          <FormButton
            render={buttonProps => (
              <Button
                id="copy-gameplan-form-submit"
                variant="contained"
                size="large"
                color="secondary"
                {...buttonProps}
              >
                {t('general.copy')}
              </Button>
            )}
          />
        </Container>
      </Container>
    </Form>
  );
}

export default CopyForm;
