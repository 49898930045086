import { Divider, Typography } from '@mui/material';
import PeriodSelector from 'Components/Features/Objectives/PeriodSelector';
import Container from 'Components/Library/BaseComponents/Container';
import StandardDialog from 'Components/Library/StandardDialog';
import useGameplanData from 'hooks/useGameplanData';
import { findPeriodWithPreviousGameplan } from '../../helpers';
import { useSelector } from 'react-redux';
import { objectivesSelectors } from 'state/ducks/objectives';
import { get } from 'lodash';
import { useState } from 'react';
import CopyForm from './CopyForm';
import { useTranslation } from 'react-i18next';

interface CopyGamePlanDialogProps {
  targetPeriod: string;
  teamId: string;
  open: boolean;
  onClose(): void;
  onSuccess(): void;
}

export const WIDGET_TYPE_LOCALIZATION_KEYS = {
  VISION_AND_PURPOSE: 'visionAndPurposeOptionTitle',
  VALUES_AND_DRIVERS: 'valuesOptionTitle',
  METHODS: 'methodsOptionTitle',
  WILL_NOT_DO: 'willNotDoOptionTitle',
  OKRS: 'okrOptionTitle',
  COMMITMENT: 'commitmentsOptionTitle',
  INTERLOCKS: 'interlocksOptionTitle',
};

function CopyGamePlanDialog(props: CopyGamePlanDialogProps) {
  const { targetPeriod, teamId, open, onClose, onSuccess } = props;
  const { t } = useTranslation();
  const periodConfig = useSelector((state: any) =>
    objectivesSelectors.selectPeriodConfig(state.main.objectives),
  );

  const gameplans = useGameplanData(teamId);
  const gameplansPerPeriod = {};

  for (const gameplan of get(gameplans, 'data', [])) {
    if (gameplan.stperiod) {
      gameplansPerPeriod[`${gameplan.ltperiod}-${gameplan.stperiod}`] = gameplan;
    }
  }

  const previousPeriod = findPeriodWithPreviousGameplan(
    periodConfig,
    targetPeriod,
    gameplansPerPeriod,
  );

  const periodFilterFunc = p => {
    // Filter out long term periods
    if (!p.stPeriodId) {
      return false;
    }
    return p.stPeriodId in gameplansPerPeriod;
  };

  const [sourcePeriod, setSourcePeriod] = useState(previousPeriod);

  // Preprocess and sort list of widgets to select
  const widgets: any = Object.values(get(gameplansPerPeriod[sourcePeriod], 'widget_data', {}));

  const opts = [];
  Object.keys(WIDGET_TYPE_LOCALIZATION_KEYS).forEach(widgetType => {
    const w = widgets.find(w => w.widget_type === widgetType);
    if (w) {
      opts.push(w);
    }
  });
  // Custom cards at the end:
  const customCards = widgets.filter(w => w.widget_type === 'CUSTOM_CARD') || [];
  opts.push(...customCards);

  if (!gameplans.ok) {
    return null;
  }
  return (
    <StandardDialog open={open} onBackdropClick={onClose} size="small">
      <Container m={3} spacing={2} alignItems="stretch">
        <Typography variant="subtitle1">{t('gameplans.addMenu.copyOptionDescription')}</Typography>
        <Container>
          <PeriodSelector
            onSelect={p => {
              setSourcePeriod(p.stPeriodId);
            }}
            value={{ stPeriodId: sourcePeriod }}
            filterFunc={periodFilterFunc}
          />
        </Container>
        <Divider />
        <CopyForm
          // Force re-mount of the form if the period changes
          key={`copy-gameplan-dialog-form+${sourcePeriod}`}
          sourceGameplanId={gameplansPerPeriod[sourcePeriod]?.id}
          targetPeriod={targetPeriod}
          options={opts}
          onClose={onClose}
          onSuccess={onSuccess}
        />
      </Container>
    </StandardDialog>
  );
}

export default CopyGamePlanDialog;
