import React, { useState } from 'react';
import AiIcon from '../Icons/AiIcon';
import { Dialog, Divider } from '@mui/material';
import SuccessUi from './SuccessUi';
import ErrorUi from './ErrorUi';
import MenuOption from '../MenuOption';
import SuggesterUi from './SuggesterUi';
import { useSelector } from 'react-redux';
import { objectivesSelectors } from 'state/ducks/objectives';
import ConfirmUi from './ConfirmUi';
import SavingResultsUi from './SavingResultsUi';
import WarningUi from './WarningUi';

interface AiGameplanSuggestionsFlowProps {
  teamId: string;
  gameplanId?: string;
  stperiod?: string;
  ltperiod?: string;
  onSuccess(): void;
}

function AiGameplanSuggestionsFlow(props: AiGameplanSuggestionsFlowProps) {
  const { teamId, gameplanId, onSuccess } = props;
  const [stage, setStage] = useState(0);
  const [results, setResults] = useState({});

  const periodConfig = useSelector(state =>
    // @ts-ignore
    objectivesSelectors.selectPeriodConfig(state.main.objectives),
  );

  const currentLtPeriod = props.ltperiod || periodConfig?.data?.activeperiod?.ltperiod;
  const currentStPeriod = props.stperiod || periodConfig?.data?.activeperiod?.stperiod;

  const onStart = () => {
    if (gameplanId) {
      openWarningUi();
    } else {
      openSuggesterUi();
    }
  };

  const onClose = () => {
    setStage(0);
  };

  const onError = () => {
    console.log('onError');
    setStage(-1);
  };

  const openWarningUi = () => {
    setStage(1);
  };

  const openSuggesterUi = () => {
    setStage(2);
  };

  const openSavingResultsUi = d => {
    setResults(d);
    setStage(3);
  };

  const openSuccessUi = () => {
    setStage(4);
  };
  return (
    <>
      <Divider />
      <MenuOption
        title="Ask AI"
        Icon={AiIcon}
        description="Use artificial intelligence to suggest a Game Plan"
        name="gameplans-ask-ai"
        onClick={onStart}
      />
      {stage !== 0 && (
        <Dialog
          open
          onClose={() => {
            if (stage < 0 || stage > 3) {
              onClose();
            }
          }}
        >
          {stage === -1 && <ErrorUi onClose={onClose} />}
          {stage === 1 && <WarningUi openSuggesterUi={openSuggesterUi} onClose={onClose} />}
          {stage === 2 && (
            <SuggesterUi
              ltPeriodId={currentLtPeriod}
              teamId={teamId}
              openSavingResultsUi={openSavingResultsUi}
              onError={onError}
            />
          )}
          {stage === 3 && (
            <SavingResultsUi
              ltPeriodId={currentLtPeriod}
              stPeriodId={currentStPeriod}
              teamId={teamId}
              gameplanId={gameplanId}
              onError={onError}
              data={results}
              openSuccessUi={openSuccessUi}
            />
          )}
          {/*stage === 2 && (
            <ConfirmUi onClose={onClose} data={results} openSavingResultsUi={openSavingResultsUi} />
          )*/}
          {stage === 4 && <SuccessUi onSuccess={onSuccess} />}
        </Dialog>
      )}
    </>
  );
}

export default AiGameplanSuggestionsFlow;
