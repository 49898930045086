import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SCHEMA_FULL } from 'Components/Library/RichTextEditor/schemas';
import Form from 'Components/Library/Forms/';
import FormTextField from 'Components/Library/Forms/Elements/FormTextField';
import LinearIndicator from 'Components/Library/Forms/Elements/LinearIndicator';
import InlineEditInput from 'Components/Library/BaseComponents/InlineEditInput';
import RichTextEditor from 'Components/Library/RichTextEditor';
import FormRichTextField from 'Components/Library/Forms/Elements/FormRichTextField';
import RichTextRenderer from 'Components/Library/RichTextRenderer';
import { objectivesActions } from 'state/ducks/objectives';
import {
  OBJ_DESCRIPTION_MAX_LENGTH,
  OBJ_NAME_MAX_LENGTH,
  OBJ_RT_DESCRIPTION_MAX_LENGTH,
} from 'config/constants';
import Container from 'Components/Library/BaseComponents/Container';
import { useSelector } from 'react-redux';
import { appstatusSelectors } from 'state/ducks/appstatus';

const schema = {
  type: 'object',
  properties: {
    objective: { type: 'string', minLength: 1, maxLength: OBJ_NAME_MAX_LENGTH },
    description: { type: 'string', maxLength: OBJ_DESCRIPTION_MAX_LENGTH },
  },
  required: ['objective'],
  additionalProperties: true,
};

const richTextSchema = {
  type: 'object',
  properties: {
    objective: { type: 'string', minLength: 1, maxLength: OBJ_NAME_MAX_LENGTH },
    description_rt: {
      richtextschema: SCHEMA_FULL,
      minLength: 0,
      maxLength: OBJ_RT_DESCRIPTION_MAX_LENGTH,
    },
  },
  required: ['objective'],
  additionalProperties: true,
};

function ObjectiveHeadline(props) {
  const { objectiveID, objectiveData, canEdit } = props;
  const { t } = useTranslation();

  const appstatus = useSelector(state => appstatusSelectors.selectStatus(state.main.appstatus));
  const useRichText = appstatus?.data?.okr_rt;
  const descriptionFieldName = useRichText ? 'description_rt' : 'description';
  const descriptionFieldValue = useRichText
    ? objectiveData?.data?.description_rt
    : objectiveData?.data?.description;

  return (
    <Form
      name="panel-view-objective-headline"
      debouncedAutoSubmit={950}
      submitActionCreator={objectivesActions.updateObjective}
      stateSlice="main.objectives"
      allowRefreshData
      refreshedDataSafeDeltaSeconds={10}
      initialValues={
        !!objectiveData &&
        objectiveData.ok && {
          objective: objectiveData.data.objective,
          [descriptionFieldName]: descriptionFieldValue,
        }
      }
      additionalProperties={{
        objectiveID,
      }}
      schema={useRichText ? richTextSchema : schema}
    >
      <LinearIndicator positioning="absolute" />
      <Container spacing={3} m={0}>
        <FormTextField
          fieldName="objective"
          render={fieldProps => (
            <InlineEditInput
              id="objectiveNameField"
              fullWidth
              multiline
              variant="h4"
              disabled={!canEdit}
              label={t('objectives.viewObjectiveNameInputPlaceholder')}
              {...fieldProps}
            />
          )}
        />
        {useRichText ? (
          canEdit ? (
            <FormRichTextField
              fieldName="description_rt"
              render={fieldProps => (
                <RichTextEditor
                  {...fieldProps}
                  label={canEdit ? t('objectives.createNewObjectiveDescriptionInputLabel') : null}
                  placeholder={
                    canEdit ? t('objectives.createNewObjectiveDescriptionInputLabel') : null
                  }
                  toolbarVariant="floating"
                  variant="inline"
                  schema={SCHEMA_FULL}
                />
              )}
            />
          ) : (
            <RichTextRenderer richTextContent={descriptionFieldValue} schema={SCHEMA_FULL} />
          )
        ) : (
          <FormTextField
            fieldName="description"
            render={fieldProps => (
              <InlineEditInput
                id="objectiveDescriptionField"
                fullWidth
                multiline
                variant="body1"
                disabled={!canEdit}
                label={canEdit ? t('objectives.createNewObjectiveDescriptionInputLabel') : null}
                {...fieldProps}
              />
            )}
          />
        )}
      </Container>
    </Form>
  );
}

ObjectiveHeadline.propTypes = {
  objectiveData: PropTypes.object,
  objectiveID: PropTypes.string,
  canEdit: PropTypes.bool,
};

export default ObjectiveHeadline;
