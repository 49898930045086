import { useSelector } from 'react-redux';
import GamePlanDashboard from './GamePlanDashboard';
import { objectivesSelectors } from 'state/ducks/objectives';
import useFeatureFlag from 'hooks/useFeatureFlag';
import PeriodNavigator from 'Components/Features/Objectives/PeriodNavigator';
import Container from 'Components/Library/BaseComponents/Container';
import { Typography } from '@mui/material';
import useGameplanData from 'hooks/useGameplanData';
import { isPast } from 'Components/Features/Objectives/PeriodSelector/SearchOverlay';
import Button from 'Components/Library/BaseComponents/Button';
import { useState } from 'react';
import AddGamePlanMenu from '../AddGamePlanButton/AddGamePlanMenu';

function GamePlanDashboardContainer(props: any) {
  const { teamId, handlePeriodChange, selectedPeriod } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const periodConfig = useSelector((state: any) =>
    objectivesSelectors.selectPeriodConfig(state.main.objectives),
  );
  const currentStPeriod = periodConfig?.data?.activeperiod?.stperiod;

  const nextShortTermPeriod = useSelector((state: any) =>
    objectivesSelectors.selectNextShortTermPeriod(state.main.objectives, selectedPeriod),
  );
  const prevShortTermPeriod = useSelector((state: any) =>
    objectivesSelectors.selectPrevShortTermPeriod(state.main.objectives, selectedPeriod),
  );

  const ltPeriod = useSelector((state: any) =>
    objectivesSelectors.selectLtPeriodForStPeriod(state.main.objectives, selectedPeriod),
  );

  const usePeriodicGameplans = useFeatureFlag('PERIODIC-GAMEPLANS');

  const gameplans = useGameplanData(teamId);

  const gameplansPerPeriod = {};
  let missingCurrentGameplan;

  if (!gameplans.ok) {
    return null;
  } else {
    if (usePeriodicGameplans) {
      for (const gameplan of gameplans.data) {
        if (gameplan.stperiod) {
          gameplansPerPeriod[`${gameplan.ltperiod}-${gameplan.stperiod}`] = gameplan.id;
        }
      }
      if (!gameplansPerPeriod[selectedPeriod]) {
        missingCurrentGameplan = true;
      }
    }
  }

  const periodFilterFunc = p => {
    // Filter out long term periods
    if (!p.stPeriodId) {
      return false;
    }
    return (
      p.stPeriodId === currentStPeriod ||
      p.stPeriodId in gameplansPerPeriod ||
      !isPast(p.periodStart)
    );
  };

  let gameplanId;
  let gameplan;
  if (gameplans?.data) {
    if (!usePeriodicGameplans) {
      gameplan = gameplans.data[0];
    } else {
      gameplanId = gameplansPerPeriod[selectedPeriod];
      if (gameplanId) {
        gameplan = gameplans.data.find(gp => gp.id === gameplanId);
      }
    }
  }

  return (
    <>
      {usePeriodicGameplans && (
        <Container alignItems="stretch" m={0} mb={2}>
          <PeriodNavigator
            handlePeriodChange={handlePeriodChange}
            filterFunc={periodFilterFunc}
            selectedPeriod={selectedPeriod}
            nextPeriod={nextShortTermPeriod}
            prevPeriod={prevShortTermPeriod}
          />
          {
            // Do not stop rendering this while anchorEl exists
            // - otherwise the callbacks will not work and the
            // copy dialog could reappear if navigating to a future
            // period that does not have a game plan
            (missingCurrentGameplan || Boolean(anchorEl)) && (
              <Container
                sx={{
                  border: theme => `2px dashed ${theme.palette.divider}`,
                  borderRadius: 1,
                }}
                p={2}
                m={0}
                mb={2}
                justifyContent="space-between"
                direction="row"
                alignItems="center"
                width="100%"
              >
                <Container direction="column">
                  <Typography variant="subtitle1">
                    We looked through the archives, but found nothing.
                  </Typography>
                  <Typography variant="caption">
                    Which unfortunately means that you do not have a game plan for the selected
                    period.
                  </Typography>
                </Container>
                <Button onClick={e => setAnchorEl(e.currentTarget)} color="secondary">
                  Fix this
                </Button>
                {Boolean(anchorEl) && (
                  <AddGamePlanMenu
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    stperiod={selectedPeriod}
                    ltperiod={ltPeriod}
                    usePeriodicGameplans={usePeriodicGameplans}
                    teamId={teamId}
                  />
                )}
              </Container>
            )
          }
        </Container>
      )}
      <GamePlanDashboard {...props} gameplan={gameplan} />
    </>
  );
}

export default GamePlanDashboardContainer;
